"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const common = {
  black: '#000',
  white: '#fff'
};
var _default = exports.default = common;