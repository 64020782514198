"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function () {
    return _GlobalStyles.default;
  }
});
var _GlobalStyles = _interopRequireDefault(require("./GlobalStyles"));